<template>
  <div class="page">
    <template v-if="list && list.length">
      <div class="page-c3">
        <div class="page-c3-one" v-for="(item, index) in list" :key="index">
          <div class="top-man">
            <div class="top-man-l">
              <img
                v-if="item.customerService&&item.customerService.avatarUrl" :src="item.customerService.avatarUrl" 
              />
              <img v-else-if="item.exhibitor.company&&item.exhibitor.company.logo" :src="item.exhibitor.company.logo" >
              <img v-else src="@/assets/img/empty.png"  />
            </div>
            <div class="top-man-r">
              <div class="name">{{ item.customerService?(item.customerService.name || item.customerService.nickName):'' }} <span>{{item.customerService?(item.customerService.jobTitle):''}}</span></div>
              <p>{{item.customerService?item.customerService.company:''}}</p>
            </div>
          </div>
          <div class="top-info">
            <!-- <p>{{item.customerService?item.customerService.phone:''}}</p> -->
            <p>{{ item.customerUsers ? item.customerUsers.email : "" }}</p>
            <span class="u-brand s-bule-border">{{item.type}}</span>
            {{item.content}}
          </div>
        </div>
      </div>
    </template>
    <div class="no_more mar_bot2" v-if="loading">
      <p>{{ $t('lang.loading')}}</p>
    </div>
    <div class="no_more mar_bot2" v-else-if="finished">
      <p>{{$t('lang.Its_the_end')}}</p>
    </div>
    <div class="more mar_bot2" @click="moreClick" v-else-if="list && list.length">
      <p>{{$t('lang.loadMore')}}</p>
    </div>
    <div class="no_more mar_bot2" v-else>{{ $t('lang.No_data') }}！</div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "cardcase",
  data() {
    return {
      list: [],
      totalCount:0,
      pageIndex:1,
      pageSize:15,
      finished:false,
      loading:false,
    };
  },
  computed: {
    ...mapGetters({
      exhibitionId: "getExhibitionId",
      user: "getUser",
    }),
  },
  created() {
    this.getData();
  },
  methods: {
    ...mapActions({
      graphqlPost: "graphqlPostByZXSEn",
    }),
    async getData() {
      this.loading=true;
      let query = `
        query($query:QueryInput!){
          exhibitorAppointmentQuery{
            query(query:$query){
                hasNextPage
                pageIndex
                totalCount
                items{
                  companyUsersId
                  content
                  createAt
                  exhibitionId
                  exhibitorId
                  id
                  exhibitor{
                    company{
                      logo
                      nameEn
                    }
                  }
                  customerService{
                    id
                    name
                    nickName
                    phone
                    jobTitle
                    company
                    avatarUrl
                  }
                  customerUsers{
                    email
                  }
                  member{
                    name
                    nickName
                    phone
                    jobTitle
                    company
                  }
                  memberId
                  state
                  type
                }
              }
            }
        }
      `;
      let where = {
        Equal: {
          n: "memberId",
          v: this.user.inMember.memberId,
        },
        EqualA: {
          n: "exhibitionId",
          v: this.exhibitionId,
        },
        EqualB: {
          n: "state",
          v: 1, // 已回应
        },
      };
      let order = [
        {
          N: "createAt",
          v: 0,
        },
      ];
      let opt = {
        query: query,
        variables: {
          query: {
            page: this.pageIndex,
            size: this.pageSize,
            where: JSON.stringify(where),
            order: JSON.stringify(order),
          },
        },
      };
      let data = await this.graphqlPost(opt);
      let res = JSON.parse(
        JSON.stringify(data.data.exhibitorAppointmentQuery.query)
      );
      // console.log('名片夹',res)
      if(this.pageIndex==1){
        this.list=res.items
      }else{
        this.list=[...new Set([...this.list,...res.items])];
      }
      this.loading=false;
      if (!res.hasNextPage) {
        this.finished = true;
      }
    },
    //点击加载更多
    moreClick(){
      if (this.finished) return;
      this.pageIndex += 1;
      this.getData()
    },
  },
};
</script>

<style scoped lang="scss">
@import "@assets/scss/my-theme/mixin.scss";
.page {
  &-c3 {
    display: flex;
    flex-wrap: wrap;
    padding: 20px 20px 0;
    width: calc(100% + 2px);
    &-one {
      padding: 10px;
      background: #fff;
      border-radius: 3px;
      width: 310px;
      height: 180px;
      margin: 0 20px 20px 0;
      border: solid 1px #e5e5e5;
      position: relative;
      &:hover {
        cursor: pointer;
        @include border_color(#1890ff);
        .top {
          &-info {
            min-height: 111px;
            max-height: 1000px;
            position: absolute;
            top: 68px;
            left: -1px;
            right: -1px;
            padding: 0 10px 6px;
            -webkit-line-clamp: none;
            display: inline-block;
            z-index: 1;
            background: #fff;
            border: 1px solid #1890ff;
        @include border_color(#1890ff);
            border-top: none;
            border-radius: 0 0 3px 3px;
          }
        }
      }
      &:nth-child(3n) {
        margin-right: 0;
      }
      .top {
        &-man {
          display: flex;
          margin-bottom: 8px;
          &-l {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            margin-right: 10px;
            img {
              display: block;
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
          }
          &-r {
            .name {
              span {
                font-size: 12px;
                color: #666;
                margin-left: 8px;
              }
            }
            p {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              width: 190px;
              color: #666;
              font-size: 12px;
              margin-top: 6px;
            }
          }
        }
        &-info {
          text-align: justify;
          max-height: 103px;
          line-height: 1.8;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          margin-bottom: -4px;
          // transition: max-height 2s;
          p{
            font-size: 16px;
          }
          .u-brand {
            height: 20px;
          }
          span {
            margin-right: 10px;
            line-height: 1.5;
          }
        }
      }
    }
  }
}
</style>
